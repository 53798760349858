import Api from "@/services/api";
import {removeEmptyParams, downloadData} from "@utils";
import moment from 'moment';

export default {
    findAll(filter) {
        return Api().get("/forecastsheets", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatResSheet(e));
            return res.data;
        });
    },
    findAllByUserId(userId, filter) {
        return Api().get("/forecastsheets/" + userId, {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatResSheet(e));
            return res.data;
        });
    },
    findAllForCurrentUser(filter) {
        return Api().get("/forecastsheets/currentuser", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatResSheet(e));
            return res.data;
        });
    },
    findAllCurrentWithRows(filter) {
        return Api().get("/forecastsheets/withrows", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatResDTO(e));
            return res.data;
        });
    },
    findAllCurrentValidatedWithRows(filter) {
        return Api().get("/forecastsheets/validated/withrows", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatResDTO(e));
            return res.data;
        });
    },
    findAllByYearWithRows(year, filter) {
        return Api().get("/forecastsheets/withrows/year/" + year, {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatResDTO(e));
            return res.data;
        });
    },
    findAllByYearValidatedWithRows(year, filter) {
        return Api().get("/forecastsheets/validated/withrows/year/" + year, {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatResDTO(e));
            return res.data;
        });
    },
    findById(id) {
        return Api().get("/forecastsheet/" + id).then(res => this.formatResSheet(res.data));
    },
    findByIdWithRowsForView(id) {
        return Api().get("/forecastsheet/" + id + "/withrows/view").then(res => this.formatResDTO(res.data));
    },
    findByIdWithRowsForEdit(id) {
        return Api().get("/forecastsheet/" + id + "/withrows/edit").then(res => this.formatResDTO(res.data));
    },
    findCurrentYear() {
        return Api().get("/forecastsheet/currentyear").then(res => res.data);
    },
    create(dto) {
        dto = this.formatRequestDTO(dto);
        return Api().post("/forecastsheet", dto).then(res => this.formatResDTO(res.data));
    },
    update(dto) {
        dto = this.formatRequestDTO(dto);
        return Api().put("/forecastsheet/" + dto.forecastSheet.id, dto).then(res => this.formatResDTO(res.data));
    },
    delete(forecastSheet) {
        return Api().delete("/forecastsheet/" + forecastSheet.id).then(() => forecastSheet);
    },
    download(forecastSheet) {
        return Api().get("/pdfreport/forecastsheet/" + forecastSheet.id, {responseType: 'blob'}).then(res => downloadData(res));
    },
    exportExcel(forecastSheet) {
        return Api().get("/forecastsheet/excel/" + forecastSheet.id, {responseType: 'blob'}).then(res => downloadData(res));
    },
    
    /*
     * FORMAT RESPONSE DATA
     */
    
    formatResDTO(d) {
        d.forecastSheet = this.formatResSheet(d.forecastSheet);
        d.forecastRows.forEach(r => this.formatResRow(r));
        return d;
    },
    formatResRow(r) {
        if (r.hour) r.hour = this.formatResHour(r.hour);
        return r;
    },
    formatResSheet(e) {
        if (e.date) e.date = new Date(e.date);
        if (e.patrolStart) e.patrolStart = this.formatResHour(e.patrolStart);
        if (e.curativeStart) e.curativeStart = this.formatResHour(e.curativeStart);
        if (e.precurativeStart) e.precurativeStart = this.formatResHour(e.precurativeStart);
        if (e.dateCreated) e.dateCreated = new Date(e.dateCreated);
        if (e.dateUpdated) e.dateUpdated = new Date(e.dateUpdated);
        if (e.dateDeleted) e.dateDeleted = new Date(e.dateDeleted);
        if (e.dateValidated) e.dateValidated = new Date(e.dateValidated);
        e.status = this.statusFor(e);
        return e;
    },
    statusFor(e) {
        if (e.year !== null) return "ARCHIVED";
        if (e.dateValidated) return "VALIDATED";
        return "REDACTION";
    },
    formatRequestDTO(e) {
        e.forecastSheet = this.formatRequestSheet(e.forecastSheet);
        e.forecastRows.forEach(r => {
            this.formatRequestRow(r);
        })
        return e;
    },
    formatRequestSheet(e) {
        if (e.patrolStart) e.patrolStart = this.formatRequestHour(e.patrolStart);
        if (e.precurativeStart) e.precurativeStart = this.formatRequestHour(e.precurativeStart);
        if (e.curativeStart) e.curativeStart = this.formatRequestHour(e.curativeStart);
        return e;
    },
    formatRequestRow(r) {
        r.hour = this.formatRequestHour(r.hour);
        return r;
    },
    formatRequestHour(hour) {
        let date = new Date(Date.now());
        date.setHours(hour.split(':')[0], hour.split(':')[1], 0, 0);
        return date.getTime();
    },
    formatResHour(hour) {
        let h = moment(new Date(hour)).format("HH:mm");
        if (h.startsWith("0")) h = h.substring(1);
        return h;
    },
    deleteList(data) {
        return Api().delete("/forecastsheets", {data: data});
    },
    downloadExcel(forecastSheetFilter) {
        return Api().get("/forecastsheets/excel", {params: forecastSheetFilter, responseType: 'blob'}).then(res => downloadData(res));
    },
}